var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CommodityHighlights"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.data.block_text ? _vm.data.block_text : "标题")+" ")]),_c('div',{staticClass:"goods-list"},[_vm._l((_vm.data.goodsList),function(item,i){return [(i < 4)?_c('div',{key:i,staticClass:"goods-item",staticStyle:{"margin-right":"26px"},on:{"click":function($event){return _vm.toPush(item.goods_id)}}},[_c('el-image',{staticStyle:{"width":"100%"},style:({
            height:
              _vm.data.block_content.length == 4
                ? '289px'
                : _vm.data.block_content.length > 4
                ? '240px'
                : '240px',
          }),attrs:{"src":item.images,"fit":"fit"}},[_c('svg',{staticStyle:{"background":"#eee"},attrs:{"slot":"error","width":"100%","height":"100%","xmlns":"http://www.w3.org/2000/svg","viewBox":"-14 -14 54 54","fill":"#b7b7b7"},slot:"error"},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"}})])]),_c('div',{staticClass:"row-hidden",staticStyle:{"height":"54px","padding":"10px","width":"100%"}},[_vm._v(" "+_vm._s(item.title ? item.title : "商品名称")+" ")]),_c('div',{staticStyle:{"padding":"10px","color":"#ee4d2d"}},[_vm._v(" RM"+_vm._s(item.price ? item.price : item.goods_price)+" ")])],1):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }