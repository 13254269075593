<template>
  <div class="ImgCategory">
    <div class="title">{{data.block_text}}</div>
    <div class="class-list">
      <template v-for="(item, i) in data.block_content">
        <div
          class="class-item"
          v-if="(type==2||item.images)&&i<9"
          @click="toPush(item.category_id)"
          :key="i"
        >
          <el-image
            :src="item.images"
            fit="fit"
            style="
              width: 100px;
              height: 100px;
              background: #eee;
              border-radius: 50%;
            "
          >
            <div slot="error" class="image-slot">
              <svg
                width="100px"
                height="100px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-14 -14 54 54"
                fill="#b7b7b7"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
                ></path>
              </svg>
            </div>
          </el-image>
          <div style="padding: 10px 0 20px;height:72px" v-if="item.category_name">
            {{ item.category_name }}
          </div>
          <div style="padding: 10px 0 20px;height:72px; color: #b7b7b7" v-else>
            分类名称
          </div>
        </div>
      </template>
      <div class="class-item" @click="toPush(0)">
        <el-avatar
          style="width: 100px; height: 100px"
          size="large"
          :src="data.block_content.images"
        >
          <div
            style="
              width: 100px;
              height: 100px;
              background: #fff;
              color: #ee4d2d;
              line-height: 100px;
            "
          >
            查看全部
            <i class="el-icon-arrow-right"></i>
          </div>
        </el-avatar>
        <div style="height: 72px"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImgCategory",
  props: ["type", "data"],
  data() {
    return {};
  },
  methods: {
    toPush(id) {
      this.$parent.handleSelect(id);
    },
  },
};
</script>
<style lang="less" scoped>
.ImgCategory {
  padding: 0 20px;
  .title {
    font-size: 16px;
    padding: 20px 0;
  }
  .class-list {
    display: flex;
    align-items: center;

    .class-item {
      text-align: center;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
</style>