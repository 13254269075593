<template>
  <div class="GoodsCategory" v-if="type == 2 || data.goodsList.length > 2">
    <span v-if="data.goodsList.length >= 3">
      <div class="title">
        <span>
          <svg
            t="1669969327641"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5474"
            width="18"
            height="18"
          >
            <path
              d="M336 972.8c-60.8-128-28.8-201.6 19.2-268.8 51.2-76.8 64-150.4 64-150.4s41.6 51.2 25.6 134.4c70.4-80 83.2-208 73.6-256 160 112 230.4 358.4 137.6 537.6 492.8-281.6 121.6-700.8 57.6-745.6 22.4 48 25.6 128-19.2 166.4-73.6-281.6-256-336-256-336 22.4 144-76.8 300.8-172.8 419.2-3.2-57.6-6.4-96-38.4-153.6-6.4 105.6-86.4 188.8-108.8 294.4C89.6 758.4 140.8 860.8 336 972.8L336 972.8z"
              p-id="5475"
              fill="#ee4d2d"
            ></path>
          </svg>
          {{data.block_text}}
        </span>
        <!-- <span
          >查看全部
          <i class="el-icon-arrow-right"></i>
        </span> -->
      </div>
      <div class="goods-list">
        <template v-for="(item, i) in data.goodsList">
          <div
            class="goods-item"
            :key="i"
                   @click="toPush(item.goods_id)"
            v-if="(type == 2 || item.goods_images)&&i<6"
          >
            <el-image
              style="width: 192px; height: 192px"
              :src="item.goods_images"
              fit="fit"
            >
              <svg
                slot="error"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-14 -14 54 54"
                fill="#b7b7b7"
                style="background: #eee"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
                ></path>
              </svg>
            </el-image>
            <div
              style="height: 50px; width: 170px; padding: 10px"
              class="row-hidden"
            >
              {{ item.goods_title ? item.goods_title : "商品名称" }}
            </div>
            <div style="padding: 10px; color: #ee4d2d">
              RM{{ item.goods_price ? item.goods_price : 0 }}
            </div>
          </div>
        </template>
      </div>
    </span>
    <div
      v-if="data.goodsList.length < 3 && type == 2"
      style="color: #999; padding: 20px; background: #fff"
    >
      <i style="font-size: 20px" class="el-icon-present"></i>
      热门促销商品少于3个，此组件不会显示在您的店铺主页
    </div>
  </div>
</template>
<script>
export default {
  name: "HotSales",
  props: ["type", "data"],
  data() {
    return {};
  },
    methods: {
    toPush(id) {
      if (id!='') {
        window.open("/goodsInfo?goodsId=" + id);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.GoodsCategory {
  // padding: 0 20px;
  .title {
    padding: 15px 0;
    color: #ee4d2d;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .goods-list {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    .goods-item {
      background: #fff;
      margin-right: 12px;
    }
  }
}
</style>