<template>
  <div class="CommodityHighlights">
    <div class="title">
      {{ data.block_text ? data.block_text : "标题" }}
    </div>
    <div class="goods-list">
      <template v-for="(item, i) in data.goodsList">
        <div
          class="goods-item"
          :key="i"
          v-if="i < 4"
          @click="toPush(item.goods_id)"
          style="margin-right: 26px"
        >
          <el-image
            :style="{
              height:
                data.block_content.length == 4
                  ? '289px'
                  : data.block_content.length > 4
                  ? '240px'
                  : '240px',
            }"
            style="width: 100%"
            :src="item.images"
            fit="fit"
          >
            <svg
              slot="error"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-14 -14 54 54"
              fill="#b7b7b7"
              style="background: #eee"
            >
              <path
                fill-rule="evenodd"
                d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
              ></path>
            </svg>
          </el-image>
          <div
            style="height: 54px; padding: 10px; width: 100%"
            class="row-hidden"
          >
            {{ item.title ? item.title : "商品名称" }}
          </div>
          <div style="padding: 10px; color: #ee4d2d">
            RM{{ item.price ? item.price : item.goods_price }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "GoodsBright",
  props: ["type", "data"],
  data() {
    return {};
  },
  methods: {
    toPush(id) {
      // this.$router.push("/goodsInfo?goodsId=" + id);
      if (id!='') {
        window.open("/goodsInfo?goodsId=" + id);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.CommodityHighlights {
  width: 100%;
  // padding: 0 20px;
  box-sizing: border-box;
  .title {
    padding: 15px 0;
  }
  .goods-list {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    .goods-item {
      width: 24%;
      background: #fff;
      cursor: pointer;
    }
  }
}
</style>