<template>
  <div class="GoodsCategory">
    <div class="title">
      {{ data.block_text ? data.block_text : "分类名称" }}
    </div>
    <div class="goods-list" v-if="type == 2">
      <template v-for="(item, i) in data.goods_list">
        <div class="goods-item" :key="i">
          <el-image
            style="width: 192px; height: 192px"
            :src="item.image"
            fit="fit"
          >
            <svg
              slot="error"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-14 -14 54 54"
              fill="#b7b7b7"
              style="background: #eee"
            >
              <path
                fill-rule="evenodd"
                d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
              ></path>
            </svg>
          </el-image>
          <div
            style="height: 50px; width: 170px; padding: 10px"
            class="row-hidden"
          >
            {{ item.goods_title }}
          </div>
          <div style="padding: 10px; color: #ee4d2d">
            RM{{ item.goods_price }}
          </div>
        </div>
      </template>
    </div>
    <div class="goods-list" v-if="type == 1">
      <template v-for="(item, i) in data.block_content.goods_list">
        <div class="goods-item" :key="i" @click="toPush(item.goods_id)">
          <el-image
            style="width: 192px; height: 192px"
            :src="item.image"
            fit="fit"
          >
            <svg
              slot="error"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-14 -14 54 54"
              fill="#b7b7b7"
              style="background: #eee"
            >
              <path
                fill-rule="evenodd"
                d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
              ></path>
            </svg>
          </el-image>
          <div
            style="height: 50px; width: 170px; padding: 10px"
            class="row-hidden"
          >
            {{ item.goods_title }}
          </div>
          <div style="padding: 10px; color: #ee4d2d">
            RM{{ item.goods_price }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "GoodsCategory",
  props: ["type", "data"],
  data() {
    return {};
  },
  methods: {
    toPush(id) {
      if (id!='') {
        window.open("/goodsInfo?goodsId=" + id);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.GoodsCategory {
  // padding: 0 20px;
  .title {
    padding: 15px 0;
  }
  .goods-list {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    .goods-item {
      background: #fff;
      margin-right: 12px;
      cursor: pointer;
    }
  }
}
</style>