var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slide",style:({
    height:
      _vm.data.block_layout == 1
        ? '620px'
        : _vm.data.block_layout == 2
        ? '697px'
        : _vm.data.block_layout == 3
        ? '1240px'
        : '620px',
  })},[(!_vm.data.block_content[0].images)?_c('div',{staticClass:"silde-nr"},[_c('svg',{attrs:{"width":"100%","height":"100%","xmlns":"http://www.w3.org/2000/svg","viewBox":"-14 -14 54 54","fill":"#b7b7b7"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"}})])]):_c('el-carousel',{staticStyle:{"height":"100%"}},_vm._l((_vm.data.block_content),function(item,i){return _c('el-carousel-item',{key:i},[_c('el-image',{staticStyle:{"width":"100%","height":"100%","cursor":"pointer"},attrs:{"src":item.images,"fit":"cover"},on:{"click":function($event){return _vm.toPush(item.goods_id)}}},[_c('svg',{attrs:{"slot":"error","width":"100%","height":"100%","xmlns":"http://www.w3.org/2000/svg","viewBox":"-14 -14 54 54","fill":"#b7b7b7"},slot:"error"},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"}})])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }