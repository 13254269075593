<template>
  <div class="bottom-goods">
    <div class="left">
      <div style="border-bottom: 1px solid #d8d8d8">分类</div>
      <div style="margin-left: -20px">
        <i class="el-icon-caret-right"></i>
        全部商品
      </div>
      <div>新商品</div>
      <div>促销中</div>
    </div>
    <div class="right">
      <div class="right-top">
        <div style="display: flex; align-items: center">
          <span>排序方式</span>
          <ul style="display: flex; align-items: center; margin-left: 10px">
            <li class="tag">热门</li>
            <li class="tag">最新</li>
            <li class="tag">精选</li>
            <li
              class="tag"
              style="
                width: 180px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span>价格</span>
              <i class="el-icon-caret-bottom"></i>
            </li>
          </ul>
        </div>
        <div>1/20</div>
      </div>
      <div class="right-in">
        <div class="goods" v-for="item in 5" :key="item">
          <div class="goods-top">
            <svg
              slot="error"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 54 61"
              fill="#e5e4e4"
              style="
                width: 60px;
                height: 60px;
                margin-left: 16px;
                margin-top: 14px;
              "
            >
              <path
                d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z"
              />
            </svg>
          </div>
          <div class="goods-bottom">
            <div class="heng1"></div>
            <div class="heng2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BottomGoods",
};
</script>
<style lang="less" scoped>
.bottom-goods {
  display: flex;
  width: 100%;
  height: 200px;
  color: #d8d8d8;
  background: #f6f6f6;

  .left {
    width: 200px;
    padding-left: 20px;
    div {
      padding: 10px 0;
    }
  }
  .right {
    width: 1212px;
    .right-top {
      width: 100%;
      height: 60px;
      background: #eee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      .tag {
        background: #fff;
        padding: 6px 8px;
        margin-right: 10px;
      }
    }
    .right-in {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .goods {
        width: 192px;
        border: 1px solid hsla(0, 0%, 85%, 0.5);
        .goods-top {
          height: 194px;
          background: #f9f9f9;
          text-align: center;
          line-height: 224px;
        }
        .goods-bottom {
          width: 100%;
          background: #fff;
          padding: 6px 10px;
          .heng1 {
            width: 90%;
            height: 10px;
            margin: 12px auto;
            margin-left: 12px;
            background: #f9f9f9;
          }
          .heng2 {
            width: 60%;
            height: 10px;
            margin: 12px auto;
            margin-left: 12px;
            background: #f9f9f9;
          }
        }
      }
    }
  }
}
</style>