<template>
  <div class="videos">

    <div class="video-card" :style="{ background: type == 1 ? '#fff' : '' }">
      <div class="video-card_in" v-if="type == 2">
        <el-image
          v-if="data.block_content.video_img"
          style="width: 100%; height: 100%"
          :src="data.block_content.video_img"
          fit="fit"
        ></el-image>
        <svg
          v-if="!data.block_content.video_img"
          data-v-891007c6=""
          t="1669623715186"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3439"
          width="20%"
          height="20%"
          class="icon"
        >
          <path
            data-v-891007c6=""
            d="M512 42.666667C252.793333 42.666667 42.666667 252.793333 42.666667 512s210.126667 469.333333 469.333333 469.333333 469.333333-210.126667 469.333333-469.333333S771.206667 42.666667 512 42.666667z m196.546667 500.493333l-266.666667 176A37.333333 37.333333 0 0 1 384 688V336.033333a37.333333 37.333333 0 0 1 57.893333-31.16l266.666667 176a37.333333 37.333333 0 0 1 0 62.32z"
            fill="#b7b7b7"
            p-id="3440"
          ></path>
        </svg>
      </div>
      <div class="video-card_in" v-else>
        <video
          controls
          :src="data.block_content.video"
          style="width: 100%; height: 100%; background: #fff"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Videos",
  props: ["type", "data"],
  mounted() {},
};
</script>
<style lang="less" scoped>
.videos {
  background: #eee;
  height: 676px;

  .video-card {
    height: 100%;
    padding: 50px;

    .video-card_in {
      height: 100%;
      border: 1px dashed #b0b0b0;
      text-align: center;
      line-height: 676px;
    }
  }
}
</style>