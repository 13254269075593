<template>
  <div class="ShopCover">
    <!-- <div class="pop" v-if="type == 2"></div> -->
    <div v-if="type == 2">
      <img style="width: 100%; height: 100%" :src="sc_bj" alt="" />
    </div>
    <div class="main-card">
      <div style="display: flex; padding: 40px 0 20px">
        <div class="left">
          <div class="content">
            <div>
              <el-avatar
                style="width: 82px; height: 82px"
                :src="info.shop_avatar"
              ></el-avatar>
            </div>
            <div style="margin-left: 6px">
              <div style="font-size: 22px; color: #fff; margin: 6px 0">
                {{ info.shop_name }}
              </div>
              <div style="font-size: 14px; color: #ffffffb3">
                {{ onlineTime }}前上线
              </div>
            </div>
          </div>
          <div
            style="
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              background-color: rgba(0, 0, 0, 0.6);
              z-index: 1;
            "
          ></div>
          <el-image
            style="width: 390px; height: 134px"
            :src="info.back_image"
            fit="cover"
          ></el-image>
        </div>
        <div class="right">
          <div>
            <svg
              enable-background="new 0 0 15 15"
              viewBox="0 0 15 15"
              x="0"
              y="0"
              stroke-width="0"
              class="shopee-svg-icon"
              style="width: 15px; height: 15px; margin-right: 10px"
            >
              <path
                d="m13 1.9c-.2-.5-.8-1-1.4-1h-8.4c-.6.1-1.2.5-1.4 1l-1.4 4.3c0 .8.3 1.6.9 2.1v4.8c0 .6.5 1 1.1 1h10.2c.6 0 1.1-.5 1.1-1v-4.6c.6-.4.9-1.2.9-2.3zm-11.4 3.4 1-3c .1-.2.4-.4.6-.4h8.3c.3 0 .5.2.6.4l1 3zm .6 3.5h.4c.7 0 1.4-.3 1.8-.8.4.5.9.8 1.5.8.7 0 1.3-.5 1.5-.8.2.3.8.8 1.5.8.6 0 1.1-.3 1.5-.8.4.5 1.1.8 1.7.8h.4v3.9c0 .1 0 .2-.1.3s-.2.1-.3.1h-9.5c-.1 0-.2 0-.3-.1s-.1-.2-.1-.3zm8.8-1.7h-1v .1s0 .3-.2.6c-.2.1-.5.2-.9.2-.3 0-.6-.1-.8-.3-.2-.3-.2-.6-.2-.6v-.1h-1v .1s0 .3-.2.5c-.2.3-.5.4-.8.4-1 0-1-.8-1-.8h-1c0 .8-.7.8-1.3.8s-1.1-1-1.2-1.7h12.1c0 .2-.1.9-.5 1.4-.2.2-.5.3-.8.3-1.2 0-1.2-.8-1.2-.9z"
              ></path>
            </svg>
            <span>商品：</span>
            <span style="color: #ee4d2d">{{ info.goods_num?info.goods_num :'--' }}</span>
          </div>
          <div style="margin: 20px 0">
            <svg
              enable-background="new 0 0 15 15"
              viewBox="0 0 15 15"
              x="0"
              y="0"
              class="shopee-svg-icon icon-rating"
              style="width: 15px; height: 15px; margin-right: 10px"
              stroke="#000"
            >
              <polygon
                fill="none"
                points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
              ></polygon>
            </svg>

            <span>评价：</span>
            <span style="color: #ee4d2d">
              <span v-if="info.comment_num == 0">尚无评价</span
              ><span v-if="info.comment_num != 0" style="margin-left: 30px"
                >{{ info.score===0||info.score?info.score:'--' }}({{ info.comment_num }}个评价)</span
              >
            </span>
          </div>
          <div>
            <svg
              enable-background="new 0 0 15 15"
              viewBox="0 0 15 15"
              x="0"
              y="0"
              class="shopee-svg-icon"
              stroke="#000"
              style="width: 15px; height: 15px; margin-right: 10px"
            >
              <g>
                <circle
                  cx="6.8"
                  cy="4.2"
                  fill="none"
                  r="3.8"
                  stroke-miterlimit="10"
                ></circle>
                <polyline
                  fill="none"
                  points="9.2 12.5 11.2 14.5 14.2 11"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke="#000"
                ></polyline>
                <path
                  d="m .8 14c0-3.3 2.7-6 6-6 2.1 0 3.9 1 5 2.6"
                  fill="none"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                ></path>
              </g>
            </svg>
            <span>加入时间：</span>
            <span>{{ addTime?addTime:'--' }}前</span>
          </div>
        </div>
      </div>
      <div style="display: flex">
        <div
          class="item"
          :class="1 == 1 ? 'active' : ''"
          style="padding: 20px 50px"
        >
          回首页
        </div>
        <div class="item" style="padding: 20px 50px" @click="handleSelect(0)">
          所有商品
        </div>
        <div
          class="item"
          style="padding: 20px 50px"
          v-for="item in categoryDataa"
          :key="item.id"
          @click="handleSelect(item.id)"
        >
          {{ item.name }}
        </div>

        <el-popover
          placement="bottom-end"
          trigger="click"
          v-if="categoryDatab.length != 0"
        >
          <div style="max-height: 300px; overflow: scroll">
            <div
              class="yus"
              style="padding: 10px 10px"
              v-for="item in categoryDatab"
              :key="item.id"
              @click="handleSelect(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
          <div
            slot="reference"
            style="
              padding: 20px;
              marigin-left: 1px solid #b0b0b0;
              cursor: pointer;
            "
          >
            <span
              style="
                border-left: 1px solid rgba(0, 0, 0, 0.09);
                padding-left: 20px;
              "
              >更多</span
            >
            <i class="el-icon-caret-bottom"></i>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShopCover",
  props: [
    "info",
    "onlineTime",
    "addTime",
    "categoryDataa",
    "categoryDatab",
    "type",
  ],
  data() {
    return {
      sc_bj: require("@/assets/img/admin/pc-home-ss.png"),
    };
  },
  mounted(){

  },
  methods: {
    handleSelect(id) {
      this.$parent.handleSelect(id);
    },
  },
};
</script>
<style lang="less" scoped>
.ShopCover {
  background: #fff;
  position: relative;
  .pop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .left {
    width: 390px;
    height: 134px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    .content {
      position: absolute;
      z-index: 2;
      padding: 20px;
      display: flex;
    }
  }
  .right {
    margin-left: 20px;
    padding-top: 20px;
    font-size: 14px;
  }
  .active {
    border-bottom: 2px solid #ee4d2d;
    color: #ee4d2d;
  }
  .item:hover {
    cursor: pointer;
    color: #ee4d2d;
  }
  #yus:hover {
    color: #ee4d2d;
  }
}
</style>