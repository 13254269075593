<template>
  <div
    class="slide"
    :style="{
      height:
        data.block_layout == 1
          ? '620px'
          : data.block_layout == 2
          ? '697px'
          : data.block_layout == 3
          ? '1240px'
          : '620px',
    }"
  >
    <div class="silde-nr" v-if="!data.block_content[0].images">
      <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-14 -14 54 54"
        fill="#b7b7b7"
      >
        <path
          fill-rule="evenodd"
          d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
        ></path>
      </svg>
    </div>
    <el-carousel style="height: 100%" v-else>
      <el-carousel-item v-for="(item, i) in data.block_content" :key="i">
        <el-image
          @click="toPush(item.goods_id)"
          style="width: 100%; height: 100%; cursor: pointer"
          :src="item.images"
          fit="cover"
        >
          <svg
            slot="error"
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-14 -14 54 54"
            fill="#b7b7b7"
          >
            <path
              fill-rule="evenodd"
              d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
            ></path></svg
        ></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: "Slide",
  props: ["type", "data"],
  methods: {
    toPush(id) {
      if (id!='') {
        window.open("/goodsInfo?goodsId=" + id);
      }
    },
  },
};
</script>
<style lang="less" >
.slide {
  width: 100%;
  background: #eee;
  padding: 50px;
  margin: auto;
  .silde-nr {
    height: 100%;
    border: 1px dashed #b0b0b0;
  }
  .el-carousel__container {
    height: 100%;
  }
}
</style>