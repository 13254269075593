<template>
  <div class="GoodsBright" v-if="type == 2 || data.goodsList.length > 2">
    <span v-if="data.goodsList.length >= 3">
      <div class="title">
        {{ data.block_text ? data.block_text : "标题" }}
      </div>

      <div class="goods-list">
        <template v-for="(item, i) in data.goodsList">
          <div
            class="goods-item"
            :key="i"
            v-if="i < 6"
            @click="toPush(item.goods_id)"
            :style="
              i == data.goodsList.length - 1
                ? 'margin-right: 0'
                : 'margin-right:26px'
            "
          >
            <el-image
              style="width: 100%; height: 240px"
              :src="item.images"
              fit="fit"
            >
              <svg
                slot="error"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-14 -14 54 54"
                fill="#b7b7b7"
                style="background: #eee"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
                ></path>
              </svg>
            </el-image>
            <div
              style="height: 54px; padding: 10px; width: 100%"
              class="row-hidden"
            >
              {{ item.title ? item.title : "商品名称" }}
            </div>
            <div style="padding: 10px; color: #ee4d2d">
              RM{{ item.price ? item.price : item.goods_price }}
            </div>
          </div>
        </template>
      </div>
    </span>
    <div
      v-if="data.goodsList.length < 3 && type == 2"
      style="color: #999; padding: 20px; background: #fff"
    >
      <i style="font-size: 20px" class="el-icon-present"></i>
      热门商品少于3个，此组件不会显示在您的店铺主页
    </div>
  </div>
</template>
<script>
export default {
  name: "GoodsBright",
  props: ["type", "data"],
  data() {
    return {};
  },
  methods: {
    toPush(id) {
      // this.$router.push("/goodsInfo?goodsId=" + id);
      if (id!='') {
        window.open("/goodsInfo?goodsId=" + id);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.GoodsBright {
  width: 100%;
  // padding: 0 20px;
  box-sizing: border-box;
  .title {
    padding: 15px 0;
  }
  .goods-list {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    .goods-item {
      width: 286px;
      background: #fff;
      cursor: pointer;
    }
  }
}
</style>


            // :style="{
            //   height:
            //     data.block_content.length == 4
            //       ? '289px'
            //       : data.block_content.length > 4
            //       ? '240px'
            //       : '240px',
            // }"