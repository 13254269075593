<template>
  <div class="SlideText">
    <div class="nrong">
      <div class="banner">
        <div>
          <div style="margin-bottom: 10px">商店介绍</div>
          <el-carousel
            v-if="
              (data.block_content && data.block_content.length != 0) ||
              type == 1
            "
            height="286px"
            style="width: 578px"
          >
            <el-carousel-item v-for="(item, i) in data.block_content" :key="i">
              <el-image
                @click="toPush(item.goods_id)"
                style="width: 578px; height: 286px; cursor: pointer"
                :src="item.images"
                fit="contain"
              >
                <div
                  slot="error"
                  style="width: 100%; height: 100%; background: #eee"
                >
                  <svg
                    width="100%"
                    height="100%"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-14 -14 54 54"
                    fill="#b7b7b7"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
                    ></path>
                  </svg></div
              ></el-image>
            </el-carousel-item>
          </el-carousel>
          <div v-else style="height: 286px; width: 578px">
            <img :src="img" alt="" />
          </div>
        </div>
        <div
          style="margin-top: 30px; padding: 0 20px; font-size: 14px"
          v-if="data.block_text || type == 1"
        >
          {{ data.block_text }}
        </div>
        <div
          v-else
          style="
            margin-top: 30px;
            padding: 0 20px;
            font-size: 14px;
            color: #b0b0b0;
          "
        >
          {{ data.block_text ? data.block_text : "在此处输入并整理文字内容" }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SlideText",
  props: ["data", "type"],
  data() {
    return {
      img: require("@/assets/img/admin/card1.png"),
    };
  },
  methods: {
    toPush(id) {
      // this.$router.push("/goodsInfo?goodsId=" + id);
      if (id!='') {
        window.open("/goodsInfo?goodsId=" + id);
      }
    },
  },
};
</script>
<style lang="less">
.SlideText {
  width: 100%;
  margin: 0 auto;
  .nrong {
    margin-top: 20px;
    padding: 20px 30px;
    background: #fff;
    .coupons-list {
      // display: flex;
      // align-items: center;
      position: relative;
      // overflow: hidden;
      .yuan-l {
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        left: -20px;
        top: 54px;
        z-index: 22;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .yuan-r {
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        right: -20px;
        top: 54px;
        z-index: 22;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .banner {
      display: flex;
    }
  }
}
.el-carousel__arrow {
  display: block !important;
}
</style>