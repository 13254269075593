<template>
  <div class="texts">
    <div class="texts-card" v-if="type == 1">{{ data.block_text }}</div>
    <div class="texts-card" v-else>
      <!-- <el-input
        @input="handleInput"
     
        type="textarea"
      
      >
      </el-input> -->
      <textarea
        :style="'height:' + textHeigth + 'px'"
        id="textarea"
        @input="handleInput"
        class="texts-card"
        v-model="data.block_text"
        v-if="data.block_text"
      ></textarea>

      <span v-else style="color: #b0b0b0">在此处输入并整理文字内容</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Texts",
  props: ["type", "data"],
  data() {
    return {
      textHeigth: 20,
    };
  },
  methods: {
    handleInput(e) {
   
    },
  },
  watch: {
    "data.block_text": function (newName, oldName) {
      if (newName == "") {
        this.textHeigth = 20;
      } else {
        let box = document.getElementById("textarea");
        if (box) {
          this.textHeigth = box.scrollHeight;
        }
      }
    },
  },
};
</script>
<style lang="less" >
.texts {
  min-height: 40px;
  padding: 20px;
  background: #fff;
  #textarea {
    font-size: 16px;
    width: 100%;
    border: none;
    resize: none;
  }
}
</style>