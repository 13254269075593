<template>
  <div
    class="multigraph"
    :style="{
      height:
        data.block_content.length == 2
          ? '600px'
          : data.block_content.length == 3
          ? '400px'
          : '300px',
    }"
  >
    <template>
      <div
        class="img-box"
        :style="{ width: 98 / data.block_content.length + '%' }"
        v-for="(item, i) in data.block_content"
        :key="i"
      >
        <el-image
          @click="toPush(item.goods_id)"
          :src="item.images"
          fit="fit"
          style="width: 100%; height: 100%"
        >
          <div
            slot="error"
            class="image-slot"
            style="margin: 50px; border: 1px dashed #b0b0b0"
          >
            <svg
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-14 -14 54 54"
              fill="#b7b7b7"
            >
              <path
                fill-rule="evenodd"
                d="M14.572 8.961a1 1 0 0 1 1.316.189l.07.093 5.43 8.205a1 1 0 0 1-.718 1.545l-.116.007H3.3a1 1 0 0 1-.888-1.46l.06-.1 3.158-4.66.096-.116a1 1 0 0 1 1.214-.17l.105.069 2.46 1.854.04.028a1 1 0 0 0 1.319-.172l.07-.093 3.363-4.947a1 1 0 0 1 .275-.272zM6.415 5a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
              ></path>
            </svg>
          </div>
        </el-image>
      </div>
    </template>
    <!-- <template v-else>
      <div v-for="item in data"></div>
    </template> -->
  </div>
</template>
<script>
export default {
  name: "Multigraph",
  props: ["type", "data"],
  mounted() {},
  methods: {
    toPush(id) {
      // this.$router.push('/goodsInfo?goodsId='+id)
      if (id!='') {
        window.open("/goodsInfo?goodsId=" + id);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.multigraph {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .img-box {
    height: 100%;
    .el-image {
      background: #eee;
    }
  }
}
</style>